<template>
  <div class="auth-content">
    <v-row
      class="auth-content-box"
    >
      <v-col
        class="auth-content-box-wrapper"
      >
        <div>
          <v-img
            src="@/assets/png/loyalty-dummy.png"
            width="187px"
            style="margin: 0 auto 34px auto;"
          />
          <div
            class="header-box"
            style="text-align: center;"
          >
            <div
              class="header"
            >
              Ссылка недействительна :’(
            </div>
            <div
              class="header-text"
            >
              Истек срок действия ссылки для восстановления пароля. Запросите новую ссылку.
            </div>
          </div>
          <div style="text-align: center;">
            <v-btn
              color="primary"
              @click="toRoute('/password/forget')"
            >
              <span
                class="iconify"
                style="margin-right: 8px;"
                data-icon="ion:log-out-outline"
                data-inline="false"
              />
              Восстановить пароль
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    data () {
      return {}
    },
    computed: {
      ...mapGetters('auth/auth', [
        'merchants',
        'merchant',
        'device',
      ]),
    },
    mounted () {
      this.$store.dispatch('auth/auth/InitDevice')
    },
    methods: {
      toRoute (path) {
        if (this.$route.path !== path) this.$router.push(path)
      },
    },
  }
</script>

<style lang="sass" scoped>
@import "~@/styles/auth"
</style>
